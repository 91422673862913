import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Api from "./ajax";

import PageLoader from "./components/common/PageLoader";

import { setCurrentUser } from "./features/currentUser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {setCheckAccess} from "./features/checkAccess";

const Login = lazy(() => import("./components/Login"));
const AppRoutes = lazy(() => import("./components/AppRoutes"));
const LoginIssue = lazy(() => import("./components/LoginIssue"));
const SMSNotifyPostProcess = lazy(() => import("./components/sms-notify-post/Process"));
const OverAllView = lazy(() => import("./components/sms-notify-post/OverAllView"));

const api = new Api();

const MainApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const dispatch = useDispatch();
  //const location = useLocation();

  useEffect(() => {
    api.post(
      "/has-auth",
      {
        inst_id: localStorage.getItem("inst_id"),
        scholar_id: localStorage.getItem("scholar_id"),
      },
      async (res) => {
        if (res.s) {
          api.post("/fee-balance",
            {
              scholar_id: localStorage.getItem("scholar_id"),
            },
            (res)=>{
              if(res.s){
                localStorage.setItem('BlockAccess',res.d.BlockAccess)
                localStorage.setItem('AppAccess',res.d.AppAccess)
                dispatch(setCheckAccess(res.d));
              }
            }
          )
          dispatch(setCurrentUser(res.d.current_user));

          localStorage.setItem("inst_id", res.d.current_user["inst_id"]);
          localStorage.setItem("scholar_id", res.d.current_user["scholar_id"]);
        } else if (res.f === 1006 || res.f === "1006") {
          localStorage.clear();

          let registrations = await navigator.serviceWorker.getRegistrations();
          for (let ii = 0; ii < registrations.length; ii++)
            registrations[ii].unregister();
          window.location.reload(true);
        } else {
          let registrations = await navigator.serviceWorker.getRegistrations();
          for (let ii = 0; ii < registrations.length; ii++)
            registrations[ii].unregister();
        }
        setIsLoggedIn(res.s);
      }
    );
  }, [dispatch]);

  useEffect(() => {
  if(localStorage.getItem("BlockAccess")==='Y')
    {
        
        localStorage.setItem('location.pathname','/fee-overdue-landing')
        onLocationChange("/fee-overdue-landing");
    }
    //console.log(window.location);
    else{
      localStorage.setItem('location.pathname',window.location.pathname)
      onLocationChange(window.location.pathname);
      //onLocationChange('/communication');
      
    }
  }, []);

  function onLocationChange(loc) {
    if (!(loc === "/sign-in" || loc === "/signin-issue")) {
      localStorage.setItem("location.pathname", loc);
    }
  }

  let routeComponents;

  if (isLoggedIn === null) {
    routeComponents = <PageLoader />;
  } else {
    if (!isLoggedIn) {
      routeComponents = (
        <BrowserRouter>
          <Routes>
            <Route
              path="sign-in"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Login authenticate={() => setIsLoggedIn(true)} />
                </Suspense>
              }
            />
            <Route
              path="signin-issue"
              element={
                <Suspense fallback={<PageLoader />}>
                  <LoginIssue />
                </Suspense>
              }
            />
            <Route
              path="s"
              element={
                <Suspense fallback={<PageLoader />}>
                  <SMSNotifyPostProcess />
                </Suspense>
              }
            />
            <Route
              path="sms-notify-post/:hash"
              element={
                <Suspense fallback={<PageLoader />}>
                  <OverAllView />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/sign-in" />}></Route>
          </Routes>
        </BrowserRouter>
      );
    } else {
      routeComponents = (
        <Suspense fallback={<PageLoader />}>
          <AppRoutes onLocationChange={onLocationChange} />
        </Suspense>
      );
    }
  }

  return <>{routeComponents}</>;
};

export default MainApp;
