import { createSlice } from "@reduxjs/toolkit";

const initialState = {AppAccess:'A', isBlock:'',ExamAccess:'',value: {} };

export const checkAccessSlice = createSlice({
  name: "checkAccess",
  initialState,
  reducers: {
    setCheckAccess: (state, action) => {
        console.log(state,'state')
        console.log(action,'action')
        state.isBlock = action.payload.BlockAccess;
        state.AppAccess= action.payload.AppAccess;
        state.ExamAccess=action.payload.ExamAccess;
      //state.mystatus = "Y";
    },
  },
});

export const { setCheckAccess } = checkAccessSlice.actions;
export default checkAccessSlice.reducer;
